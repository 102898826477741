<template>
  <base-wrapper>
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-input
          type="search"
          :shadow="false"
          placeholder="Cari Kode"
          debounce
          v-model="filter.search"
          @native-input="loadOrders"
        />
        <base-select
          :expand="false"
          :shadow="false"
          :options="[
            { key: 'all', label: 'Status', value: null },
            { key: 'draft', label: 'Draft', value: false },
            { key: 'valid', label: 'Valid', value: true },
          ]"
          v-model="filter.is_valid_for_payment"
          @change="loadOrders"
        />
      </div>

      <datatable
        :scroll-x="false"
        :columns="tableColumns"
        :total="orders.meta.page.total"
        :perPage="orders.meta.page.perPage"
        :currentPage="orders.meta.page.currentPage"
        :meta="orders.meta"
        cursor
        @pagechanged="onPageChanged"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="(order, index) in orders.data"
            :key="index"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="
              onView(
                getSingleIncluded(orders, order.relationships.order.data.id)
              )
            "
          >
            <td :class="classes.td">
              <p>
                <span class="font-bold text-gray-900">{{
                  order.attributes.origin_code
                }}</span>
                / {{ order.attributes.destination_code }}
              </p>
            </td>
            <td :class="classes.td">
              {{ order.attributes.updatedAt | formatDate }}
            </td>
            <td :class="classes.td">
              {{
                getSingleIncluded(orders, order.relationships.order.data.id)
                  .attributes.destination_office_code
              }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                getSingleIncluded(orders, order.relationships.order.data.id)
                  .attributes.grand_total_price | toCurrency
              }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                getSingleIncluded(orders, order.relationships.order.data.id)
                  .attributes.payment_amount | toCurrency
              }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                :color="
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.is_valid_for_payment
                    ? 'indigo'
                    : 'yellow'
                "
                >{{ order.attributes.order_status }}</base-badge
              >
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-order-modal
      :visible="viewModal.visible"
      :order-id="viewModal.id"
      :columns="[
        'code',
        'warehouse',
        'office',
        'date',
        'user',
        'area',
        'shipment_type',
        'address',
      ]"
      readonly
      @close="viewModal.visible = false"
      @validated="onValidated"
      @deleted="onDeleted"
    />

    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import ViewOrderModal from '@/components/order/view-order-modal.vue';

export default {
  mixins: [requestMixin],
  components: { ViewOrderModal },
  data() {
    return {
      filter: {
        search: null,
        is_valid_for_payment: null,
      },
      loading: false,
      orders: {
        data: [],
        meta: {
          page: {},
        },
      },
      viewModal: {
        visible: false,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    tableColumns() {
      return [
        { id: 'code', name: 'Kode Restock' },
        { id: 'date', name: 'Tanggal' },
        { id: 'destination', name: 'Kantor Stockist' },
        { id: 'price', name: 'Total Harga', theadClass: 'text-right' },
        { id: 'payment', name: 'Total Bayar', theadClass: 'text-right' },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
      ];
    },
  },
  methods: {
    async loadOrders(params) {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/orders', {
        params: {
          'page[limit]': 5,
          'filter[order_type]': 'restock-loan',
          'filter[origin_warehouse_id]': this.me.current_warehouse,
          'filter[search]': this.filter.search,
          ...(this.filter.is_valid_for_payment === null
            ? {}
            : this.filter.is_valid_for_payment
            ? { 'filter[not-order_status]': 'Draft' }
            : { 'filter[order_status]': 'Draft' }),
          include: 'order',
          'fields[simple-orders]':
            'origin_code,destination_code,updatedAt,order_status,order',
          'fields[orders]':
            'destination_office_code,grand_total_price,payment_amount,is_valid_for_payment',
          ...params,
        },
      });

      if (!err) {
        this.orders = res;
      }

      this.loading = false;
    },
    onDeleted() {
      this.loadOrders();

      this.viewModal.visible = false;
    },
    onPageChanged(page) {
      this.loadOrders({
        ...page,
      });
    },
    onValidated() {
      this.loadOrders();

      this.viewModal.visible = false;
    },
    onView(order) {
      this.viewModal.id = order.id;
      this.viewModal.visible = true;
    },
  },
  created() {
    this.loadOrders();
  },
};
</script>
